import { initialize } from 'redux-form';
import moment from 'moment';
import { getJob } from '../../../HttpRequests/jobs';
import { FORM_NAME } from '../Form';
import { getProps } from '../props';
import fetchPlotNotes from './fetchPlotNotes'

export default () => async (dispatch) => {
  
  const { jobId, orderId, sugTag, sugMetres, legacy } = getProps();

  if (jobId) {

    const params = {
      include: 'order,order.site,fitter_set_by_user,inspection_set_by_user'
    }

    const response = await getJob({ id: jobId, params });

    const job = response.data.data;
    const { order } = job;

    dispatch(initialize(FORM_NAME, Object.assign({}, job, {
      dates: `${job.start_date},${job.end_date}`,
      flags: ['town_house', 'via_thetford', 'sent', 'collected', 'ordered', 'picked'].filter(key => job[key]),
      plot_number: job.order ? job.order.plot_number : null,
      site: order ? `${order.site ? order.site.name : order.site_custom} @ ${order.site ? order.site.town : order.town_custom}` : job.legacy_site,
      delivery_date: order.delivery_date,
    })))
    
  } else {

    dispatch(initialize(FORM_NAME, {
      order_id: orderId,
      tag: sugTag,
      metres: sugMetres,
      legacy: legacy ? 1 : 0,
    }))

  }

  dispatch(fetchPlotNotes())

}