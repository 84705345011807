import { connect } from 'react-redux'
import Item from './Item'

import selectMaterials from './Selectors/selectMaterials'
import selectSuppliers from './Selectors/selectSuppliers'

import onEditField from './Handlers/onEditField'
import onClickDimensions from './Handlers/onClickDimensions'
import onClickAction from './Handlers/onClickAction'
import onClickOnCostSheet from './Handlers/onClickOnCostSheet'
import onClickOnJobSheet from './Handlers/onClickOnJobSheet'
import onClickOnSupplySheet from './Handlers/onClickOnSupplySheet'
import onClickSupplySheetNotes from './Handlers/onClickSupplySheetNotes'

const mapStateToProps = (state) => ({
  materials: selectMaterials(state),
  suppliers: selectSuppliers(state),
})

const actionCreators = {
  onEditField,
  onClickDimensions,
  onClickAction,
  onClickOnCostSheet,
  onClickOnJobSheet,
  onClickOnSupplySheet,
  onClickSupplySheetNotes,
}

export default connect(mapStateToProps, actionCreators)(Item)
