import React from 'react';
import PropTypes from 'prop-types';
import { NavLink } from 'react-router-dom';
import { Field } from 'redux-form';
import { SelectField, InputField, InputNumberField, CheckboxGroupField, CheckboxField, DatePickerField, TextAreaField } from 'redux-form-antd-fields';
import { find, get } from 'lodash';
import Panel from '../../../Panel';
import styles from './Component.css';

const leftLabelColSpan = 7;
const leftWrapperColSpan = 15;

const rightLabelColSpan = 6;
const rightWrapperColSpan = 16;

const CheckboxLabel = ({ text }) => <div className={styles.checkboxLabel}>{text}</div>

export default class extends React.PureComponent {

  static propTypes = {
    order: PropTypes.object.isRequired,
    submitting: PropTypes.bool.isRequired,
    orderNumberOptions: PropTypes.array.isRequired,
    orderCategoryOptions: PropTypes.array.isRequired,
    builderOptions: PropTypes.array.isRequired,
    siteOptions: PropTypes.array.isRequired,
    plotTypeOptions: PropTypes.array.isRequired,
    orderType: PropTypes.string,
    builderId: PropTypes.number,
    originalOrderId: PropTypes.number,
    credit: PropTypes.bool.isRequired,
    customEntry: PropTypes.bool.isRequired,
    onBlurOrder: PropTypes.func.isRequired,
    onChangeFlags: PropTypes.func.isRequired,
    onChangeOrder: PropTypes.func.isRequired,
    onChangeOrderSearch: PropTypes.func.isRequired,
    onChangeBuilder: PropTypes.func.isRequired,
    onChangeSite: PropTypes.func.isRequired,
    onChangePlotType: PropTypes.func.isRequired,
  }

  static defaultProps = {
    orderType: null,
    builderId: null,
    originalOrderId: null,
  }

  render () {

    const {
      order,
      submitting,
      orderNumberOptions,
      orderCategoryOptions,
      builderOptions,
      siteOptions,
      plotTypeOptions,
      orderType,
      builderId,
      originalOrderId,
      credit,
      customEntry,
      onBlurOrder,
      onChangeFlags,
      onChangeOrder,
      onChangeOrderSearch,
      onChangeBuilder,
      onChangeSite,
      onChangePlotType,
    } = this.props;

    const orderTypeOptions = [
      { value: 'order', label: 'Order' },
      { value: 'remedial', label: 'Remedial' }
    ]

    const creditStatusOptions = [
      { value: 'To Be Applied For', label: 'To Be Applied For' },
      { value: 'Awaiting Credit', label: 'Awaiting Credit' },
      { value: 'Credit Received', label: 'Credit Received' },
    ]

    const protection = get(find(builderOptions, builderOption => builderOption.value === builderId), 'protection')

    if (orderType === 'order' && credit) return (

      <Panel title="Details">

        <div style={{ padding: 30, display: 'flex' }}>

          <div style={{ width: 450 }}>

            <Field
              name="order_type"
              label="Type"
              disabled
              component={SelectField}
              options={orderTypeOptions}
              labelCol={{ span: leftLabelColSpan }}
              wrapperCol={{ span: leftWrapperColSpan }}
            />

            <Field
              name="flags"
              label=" "
              colon={false}
              component={CheckboxGroupField}
              disabled={submitting}
              labelCol={{ span: leftLabelColSpan }}
              wrapperCol={{ span: leftWrapperColSpan }}
              options={[
                { value: 'credit', label: <CheckboxLabel text="Credit" /> },
                { value: 'custom_entry', label: <CheckboxLabel text="Custom Entry" /> },
                { value: 'private', label: <CheckboxLabel text="Private" /> }
              ]}
              onChange={onChangeFlags}
            />

          </div>

          <div style={{ width: 530 }}>

            <Field
              component={InputField}
              name="credit_reference"
              label="Credit Ref"
              disabled={submitting}
              labelCol={{ span: rightLabelColSpan }}
              wrapperCol={{ span: rightWrapperColSpan }}
            />

            <Field
              name="builder_id"
              label="Company"
              disabled={submitting}
              component={SelectField}
              options={builderOptions}
              labelCol={{ span: rightLabelColSpan }}
              wrapperCol={{ span: rightWrapperColSpan }}
              required
              onChange={onChangeBuilder}
            />

            <Field
              name="order_category_id"
              label="Category"
              disabled={submitting}
              component={SelectField}
              options={orderCategoryOptions}
              labelCol={{ span: rightLabelColSpan }}
              wrapperCol={{ span: rightWrapperColSpan }}
              required
            />

            <Field
              name="date"
              label="Date"
              disabled={submitting}
              component={DatePickerField}
              labelCol={{ span: rightLabelColSpan }}
              wrapperCol={{ span: 8 }}
              required
            />

            <Field
              name="custom_price_1"
              label="Amount (£)"
              disabled={submitting}
              component={InputNumberField}
              precision={2}
              labelCol={{ span: rightLabelColSpan }}
              wrapperCol={{ span: 8 }}
            />

            <Field
              name="credit_status"
              label="Credit Status"
              disabled={submitting}
              component={SelectField}
              options={creditStatusOptions}
              labelCol={{ span: rightLabelColSpan }}
              wrapperCol={{ span: 8 }}
            />

          </div>

        </div>

      </Panel>

    )

    if (orderType === 'remedial' && credit) return (

      <Panel title="Details">

        <div style={{ padding: 30, display: 'flex' }}>

          <div style={{ width: 450 }}>

            <Field
              name="order_type"
              label="Type"
              disabled
              component={SelectField}
              options={orderTypeOptions}
              labelCol={{ span: leftLabelColSpan }}
              wrapperCol={{ span: leftWrapperColSpan }}
            />

            <Field
              name="flags"
              label=" "
              colon={false}
              component={CheckboxGroupField}
              disabled={submitting}
              labelCol={{ span: leftLabelColSpan }}
              wrapperCol={{ span: leftWrapperColSpan }}
              options={[
                { value: 'credit', label: <CheckboxLabel text="Credit" /> },
                { value: 'custom_entry', label: <CheckboxLabel text="Custom Entry" /> },
                { value: 'private', label: <CheckboxLabel text="Private" /> }
              ]}
              onChange={onChangeFlags}
            />

            <div style={{ height: 26 }} />

            <Field
              name="original_order_id"
              label="Original Order"
              component={SelectField}
              disabled={submitting || customEntry}
              labelCol={{ span: leftLabelColSpan }}
              wrapperCol={{ span: leftWrapperColSpan }}
              options={orderNumberOptions}
              onSearch={onChangeOrderSearch}
              onChange={onChangeOrder}
              onBlur={onBlurOrder}
            />

            <div style={{ height: 25, marginLeft: 130, display: 'flex', alignItems: 'start' }}>

              <NavLink to={`/portal/orders/${originalOrderId}`}>
                &#x2192; Go to Order
              </NavLink>

            </div>

          </div>

          <div style={{ width: 530 }}>

            <Field
              component={InputField}
              name="credit_reference"
              label="Credit Ref"
              disabled={submitting}
              labelCol={{ span: rightLabelColSpan }}
              wrapperCol={{ span: rightWrapperColSpan }}
            />

            <Field
              name="builder_id"
              label="Company"
              disabled={submitting}
              component={SelectField}
              options={builderOptions}
              labelCol={{ span: rightLabelColSpan }}
              wrapperCol={{ span: rightWrapperColSpan }}
              required
              onChange={onChangeBuilder}
            />

            <Field
              name="order_category_id"
              label="Category"
              disabled={submitting}
              component={SelectField}
              options={orderCategoryOptions}
              labelCol={{ span: rightLabelColSpan }}
              wrapperCol={{ span: rightWrapperColSpan }}
              required
            />

            <Field
              name="date"
              label="Date"
              disabled={submitting}
              component={DatePickerField}
              labelCol={{ span: rightLabelColSpan }}
              wrapperCol={{ span: 8 }}
              required
            />

            <Field
              name="custom_price_1"
              label="Amount (£)"
              disabled={submitting}
              component={InputNumberField}
              precision={2}
              labelCol={{ span: rightLabelColSpan }}
              wrapperCol={{ span: 8 }}
            />

            <Field
              name="credit_status"
              label="Credit Status"
              disabled={submitting}
              component={SelectField}
              options={creditStatusOptions}
              labelCol={{ span: rightLabelColSpan }}
              wrapperCol={{ span: 8 }}
            />

          </div>

        </div>

      </Panel>

    )

    return (

      <Panel title="Details">

        <div style={{ padding: 30, display: 'flex' }}>

          <div style={{ width: 450 }}>

            <Field
              name="order_type"
              label="Type"
              disabled
              component={SelectField}
              options={orderTypeOptions}
              labelCol={{ span: leftLabelColSpan }}
              wrapperCol={{ span: leftWrapperColSpan }}
            />

            <Field
              name="flags"
              label=" "
              colon={false}
              component={CheckboxGroupField}
              disabled={submitting}
              labelCol={{ span: leftLabelColSpan }}
              wrapperCol={{ span: leftWrapperColSpan }}
              options={[
                { value: 'credit', label: <CheckboxLabel text="Credit" /> },
                { value: 'custom_entry', label: <CheckboxLabel text="Custom Entry" /> },
                { value: 'private', label: <CheckboxLabel text="Private" /> }
              ]}
              onChange={onChangeFlags}
            />

            <div style={{ height: 26 }} />

            {!customEntry && (
            <React.Fragment>
              
              {orderType === 'remedial' && (

                <React.Fragment>

                  <Field
                    name="original_order_id"
                    label="Original Order"
                    component={SelectField}
                    disabled={submitting || customEntry}
                    labelCol={{ span: leftLabelColSpan }}
                    wrapperCol={{ span: leftWrapperColSpan }}
                    options={orderNumberOptions}
                    onSearch={onChangeOrderSearch}
                    onChange={onChangeOrder}
                    onBlur={onBlurOrder}
                  />

                  <div style={{ height: 25, marginLeft: 130, display: 'flex', alignItems: 'start' }}>

                    <NavLink to={`/portal/orders/${originalOrderId}`}>
                      &#x2192; Go to Order
                    </NavLink>

                  </div>

                </React.Fragment>

              )}

              <Field
                name="order_category_id"
                label="Category"
                disabled={submitting}
                component={SelectField}
                options={orderCategoryOptions}
                labelCol={{ span: leftLabelColSpan }}
                wrapperCol={{ span: leftWrapperColSpan }}
                required
              />
              
              <Field
                name="builder_id"
                label="Builder"
                disabled={submitting}
                component={SelectField}
                options={builderOptions}
                labelCol={{ span: leftLabelColSpan }}
                wrapperCol={{ span: leftWrapperColSpan }}
                required
                onChange={onChangeBuilder}
              />
              
              <Field
                name="site_id"
                label="Site"
                disabled={submitting}
                component={SelectField}
                options={siteOptions}
                labelCol={{ span: leftLabelColSpan }}
                wrapperCol={{ span: leftWrapperColSpan }}
                required
                onChange={onChangeSite}
              />

              {orderType === 'order' && (
                <Field
                  name="plot_type_id"
                  label="Plot Type"
                  disabled={submitting}
                  component={SelectField}
                  options={plotTypeOptions}
                  labelCol={{ span: leftLabelColSpan }}
                  wrapperCol={{ span: leftWrapperColSpan }}
                  onChange={onChangePlotType}
                />
              )}

              <Field
                name="plot_number"
                label="Plot No"
                disabled={submitting}
                component={InputField}
                labelCol={{ span: leftLabelColSpan }}
                wrapperCol={{ span: leftWrapperColSpan }}
                required
              />

              <Field
                name="builder_po_number"
                label="Builder PO No"
                disabled={submitting}
                component={InputField}
                options={orderCategoryOptions}
                labelCol={{ span: leftLabelColSpan }}
                wrapperCol={{ span: leftWrapperColSpan }}
              />

            </React.Fragment>
            )}

            {customEntry && (
            <React.Fragment>

              <Field
                name="order_category_custom"
                label="Category"
                disabled={submitting}
                component={InputField}
                labelCol={{ span: leftLabelColSpan }}
                wrapperCol={{ span: leftWrapperColSpan }}
                required
              />
              
              <Field
                name="builder_custom"
                label="Builder"
                disabled={submitting}
                component={InputField}
                labelCol={{ span: leftLabelColSpan }}
                wrapperCol={{ span: leftWrapperColSpan }}
                required
              />
              
              <Field
                name="site_custom"
                label="Site"
                disabled={submitting}
                component={InputField}
                labelCol={{ span: leftLabelColSpan }}
                wrapperCol={{ span: leftWrapperColSpan }}
                required
              />
              
              <Field
                name="town_custom"
                label="Town"
                disabled={submitting}
                component={InputField}
                labelCol={{ span: leftLabelColSpan }}
                wrapperCol={{ span: leftWrapperColSpan }}
                required
              />

              <Field
                name="plot_number"
                label="Plot No"
                disabled={submitting}
                component={InputField}
                labelCol={{ span: leftLabelColSpan }}
                wrapperCol={{ span: leftWrapperColSpan }}
                required
              />

              <Field
                name="builder_po_number"
                label="Builder PO No"
                disabled={submitting}
                component={InputField}
                options={orderCategoryOptions}
                labelCol={{ span: leftLabelColSpan }}
                wrapperCol={{ span: leftWrapperColSpan }}
              />

            </React.Fragment>
            )}
          
          </div>

          <div style={{ width: 530 }}>

            <Field
              name="date"
              label="Created Date"
              disabled={submitting}
              component={DatePickerField}
              labelCol={{ span: rightLabelColSpan }}
              wrapperCol={{ span: 8 }}
              required
            />

            <Field
              name="ordered_date"
              label="Ordered Date"
              disabled={submitting}
              component={DatePickerField}
              labelCol={{ span: rightLabelColSpan }}
              wrapperCol={{ span: 8 }}
            />

            <Field
              name="delivery_date"
              label="Delivery Date"
              disabled={submitting}
              component={DatePickerField}
              labelCol={{ span: rightLabelColSpan }}
              wrapperCol={{ span: 8 }}
            />

            <div style={{ height: 20 }} />

            {orderType === 'order' && (

              <div>

                <Field
                  name="sale_price"
                  label="Sale Price (£)"
                  disabled={!order.can_edit_price_fields || submitting}
                  component={InputNumberField}
                  labelCol={{ span: rightLabelColSpan }}
                  wrapperCol={{ span: 8 }}
                  precision={2}
                />

                <Field
                  name="contract_works_price"
                  label="Contract Works (£)"
                  disabled={!order.can_edit_price_fields || submitting}
                  component={InputNumberField}
                  labelCol={{ span: rightLabelColSpan }}
                  wrapperCol={{ span: 8 }}
                  precision={2}
                />

                <Field
                  name="customer_extra_price"
                  label="Customer Extra (£)"
                  disabled={!order.can_edit_price_fields || submitting}
                  component={InputNumberField}
                  labelCol={{ span: rightLabelColSpan }}
                  wrapperCol={{ span: 8 }}
                  precision={2}
                />

                <Field
                  name="matwell_price"
                  label="Matwell (£)"
                  disabled={!order.can_edit_price_fields || submitting}
                  component={InputNumberField}
                  labelCol={{ span: rightLabelColSpan }}
                  wrapperCol={{ span: 8 }}
                  precision={2}
                />

                {!!protection && (

                  <Field
                    name="protection_price"
                    label="Protection (£)"
                    disabled={submitting}
                    component={InputNumberField}
                    labelCol={{ span: rightLabelColSpan }}
                    wrapperCol={{ span: 8 }}
                    precision={2}
                  />

                )}

              </div>

            )}

            {orderType === 'remedial' && (

              <div>

                <Field
                  component={InputField}
                  name="customer_email"
                  label="Customer Email"
                  disabled={submitting}
                  labelCol={{ span: rightLabelColSpan }}
                  wrapperCol={{ span: rightWrapperColSpan }}
                />

                <Field
                  component={InputField}
                  name="customer_phone_number"
                  label="Customer Phone"
                  disabled={submitting}
                  labelCol={{ span: rightLabelColSpan }}
                  wrapperCol={{ span: rightWrapperColSpan }}
                />

                <Field
                  name="remedial_overview"
                  label="Case Overview"
                  placeholder="Notes about the remedial case"
                  disabled={submitting}
                  component={TextAreaField}
                  labelCol={{ span: rightLabelColSpan }}
                  wrapperCol={{ span: rightWrapperColSpan }}
                  style={{ height: 77, marginTop: 4, marginBottom: 4 }}
                />

                <Field
                  name="custom_price_1"
                  label="Price 1 (£)"
                  disabled={submitting}
                  component={InputNumberField}
                  precision={2}
                  labelCol={{ span: rightLabelColSpan }}
                  wrapperCol={{ span: rightWrapperColSpan }}
                  fieldCol={{ span: 8 }}
                  afterCol={{ span: 16 }}
                  renderAfter={() => (
                    <Field
                      name="custom_price_1_label"
                      disabled={submitting}
                      component={InputField}
                      placeholder="Note"
                    />
                  )}
                />

                <Field
                  name="custom_price_2"
                  label="Price 2 (£)"
                  disabled={submitting}
                  component={InputNumberField}
                  precision={2}
                  labelCol={{ span: rightLabelColSpan }}
                  wrapperCol={{ span: rightWrapperColSpan }}
                  fieldCol={{ span: 8 }}
                  afterCol={{ span: 16 }}
                  renderAfter={() => (
                    <Field
                      name="custom_price_2_label"
                      disabled={submitting}
                      component={InputField}
                      placeholder="Note"
                    />
                  )}
                />

                <Field
                  name="custom_price_3"
                  label="Price 3 (£)"
                  disabled={submitting}
                  component={InputNumberField}
                  precision={2}
                  labelCol={{ span: rightLabelColSpan }}
                  wrapperCol={{ span: rightWrapperColSpan }}
                  fieldCol={{ span: 8 }}
                  afterCol={{ span: 16 }}
                  renderAfter={() => (
                    <Field
                      name="custom_price_3_label"
                      disabled={submitting}
                      component={InputField}
                      placeholder="Note"
                    />
                  )}
                />

              </div>

            )}

            <Field
              name="sage_export_skip"
              label="Skip Sage"
              component={CheckboxField}
              disabled={submitting}
              labelCol={{ span: rightLabelColSpan }}
              wrapperCol={{ span: rightWrapperColSpan }}
              fieldCol={{ span: 8 }}
              afterCol={{ span: 16 }}
            />

          </div>

        </div>

      </Panel>

    )
  }
}