import { createSelector } from 'reselect'

const selectUser = state => state.user

// 1: Lewis Bird
// 3: Nick Hutson
// 4: Russell Freedman
// 5: Joshua Freedman
// 6: Joanna Gold
// 7: Andrew Ostacchini
// 8: Jordan Heath
// 14: Lizzie Ashcroft
// 18: Warehouse
// 23: Chloe Martin
// 30: Paige Drake
// 38: Kenny Gould
// 45: Steve Ford
// 51: Conor Parsons
// 54: Sam Brown

export default createSelector(
  selectUser,
  (user) => [1, 3, 4, 5, 6, 7, 8, 14, 23, 30, 38, 45, 51, 54].indexOf(user.id) !== -1,
)
