import React from 'react';
import PropTypes from 'prop-types';
import { NavLink } from 'react-router-dom';
import { find } from 'lodash';
import { Field } from 'redux-form';
import { Button, Tooltip } from 'antd';
import { InputField, TextAreaField, SelectField, DatePickerField, DateRangePickerField, CheckboxGroupField } from 'redux-form-antd-fields';
import styles from './Component.css';
import tags from '../../../../Core/Misc/tags';

const labelColSpan = 6;
const wrapperColSpan = 16;

export default class extends React.PureComponent{

  static propTypes = {
    submitting: PropTypes.bool,
    fitters: PropTypes.array.isRequired,
    inspectors: PropTypes.array.isRequired,
    orderNumbers: PropTypes.array.isRequired,
    piggybackOrderNumbers: PropTypes.array.isRequired,
    legacy: PropTypes.number,
    orderId: PropTypes.number,
    fitterId: PropTypes.number,
    fitterInfo: PropTypes.string,
    inspectionInfo: PropTypes.string,
    canFindAvailableFitters: PropTypes.bool.isRequired,
    onChangeDates: PropTypes.func.isRequired,
    onChangeFitter: PropTypes.func.isRequired,
    onChangeInspection: PropTypes.func.isRequired,
    onChangeFlags: PropTypes.func.isRequired,
    onChangeOrderSearch: PropTypes.func.isRequired,
    onChangePiggybackOrderSearch: PropTypes.func.isRequired,
    onChangeOrder: PropTypes.func.isRequired,
    onBlurOrder: PropTypes.func.isRequired,
    onBlurPiggybackOrder: PropTypes.func.isRequired,
    onClickFindFitter: PropTypes.func.isRequired,
    onClickNotes: PropTypes.func.isRequired,
  }

  static defaultProps = {
    submitting: false,
    legacy: null,
    orderId: null,
    fitterId: null,
    fitterInfo: null,
    inspectionInfo: null
  }

  render () {

    const {
      submitting,
      fitters,
      inspectors,
      orderNumbers,
      piggybackOrderNumbers,
      legacy,
      orderId,
      fitterId,
      fitterInfo,
      inspectionInfo,
      canFindAvailableFitters,
      onChangeDates,
      onChangeFitter,
      onChangeInspection,
      onChangeFlags,
      onChangeOrderSearch,
      onChangePiggybackOrderSearch,
      onChangeOrder,
      onBlurOrder,
      onBlurPiggybackOrder,
      onClickFindFitter,
      onClickNotes,
    } = this.props;

    const CheckboxLabel = ({ text }) => <div className={styles.checkboxLabel}>{text}</div>

    const tagOptions = tags.map(item => ({ value: item.name, label: item.name}))

    const fitterOptions = fitters.filter(fitter => !fitter.archived_at).map(item => ({ value: item.id, label: item.name }))

    // inserts fitter as option if archived
    if (!find(fitterOptions, option => option.value === fitterId)) {
      const selectedFitter = find(fitters, fitter => fitter.id === fitterId)
      if (selectedFitter) {
        fitterOptions.push({ value: selectedFitter.id, label: selectedFitter.name })
      }
    }

    const inspectorOptions = inspectors.map(item => ({ value: item.id, label: item.name }))

    const orderNumberOptions = orderNumbers.map(item => ({ value: item.id, label: String(item.order_number || item.legacy_order_number) }))

    const piggybackOrderNumberOptions = piggybackOrderNumbers.map(item => ({ value: item.id, label: String(item.order_number || item.legacy_order_number) }))

    return (

      <div className={styles.wrapper}>

        <div className={styles.left}>

          <Field
            name="tag"
            label="Tag"
            component={SelectField}
            disabled={submitting}
            labelCol={{ span: labelColSpan }}
            wrapperCol={{ span: wrapperColSpan }}
            options={tagOptions}
            required
          />

          <Field
            name="dates"
            label="Dates"
            component={DateRangePickerField}
            disabled={submitting}
            labelCol={{ span: labelColSpan }}
            wrapperCol={{ span: wrapperColSpan }}
            onChange={onChangeDates}
            required
          />

          <Field
            name="metres"
            label="Metres"
            component={InputField}
            disabled={submitting}
            labelCol={{ span: labelColSpan }}
            wrapperCol={{ span: wrapperColSpan }}
          />

          <Field
            name="fitter_id"
            label="Fitter"
            component={SelectField}
            disabled={submitting}
            labelCol={{ span: labelColSpan }}
            wrapperCol={{ span: wrapperColSpan }}
            fieldCol={{ span: 20 }}
            afterCol={{ span: 4 }}
            options={fitterOptions}
            onChange={onChangeFitter}
            renderAfter={() => (
              <Button
                type="primary"
                icon="search"
                onClick={onClickFindFitter}
                disabled={!canFindAvailableFitters}
                style={{ width: '100%', height: 31 }}
              />
            )}
          />

          {fitterInfo && (
            <div className={styles.fitterInfo}>
              {fitterInfo}
            </div>
          )}

          <Field
            name="inspector_id"
            label="Inspection"
            component={SelectField}
            disabled={submitting}
            labelCol={{ span: labelColSpan }}
            wrapperCol={{ span: wrapperColSpan }}
            fieldCol={{ span: 12 }}
            afterCol={{ span: 12 }}
            options={inspectorOptions}
            onChange={onChangeInspection}
            renderAfter={() => (
              <Field
                name="inspection_date"
                label={false}
                component={DatePickerField}
                disabled={submitting}
                fieldCol={{ span: 18 }}
                afterCol={{ span: 6 }}
                onChange={onChangeInspection}
                renderAfter={() => (
                  <Tooltip title='Inspection Notes'>
                    <Button
                      type="primary"
                      icon="info-circle"
                      onClick={onClickNotes}
                      style={{ width: '100%', height: 31 }}
                    />
                  </Tooltip>
                )}
              />
            )}
          />

          {inspectionInfo && (
            <div className={styles.inspectionInfo}>
              {inspectionInfo}
            </div>
          )}

          <Field
            name="flags"
            label="Flags"
            component={CheckboxGroupField}
            disabled={submitting}
            labelCol={{ span: labelColSpan }}
            wrapperCol={{ span: wrapperColSpan }}
            onChange={onChangeFlags}
            options={[
              { value: 'town_house', label: <CheckboxLabel text="Town House" /> },
              { value: 'via_thetford', label: <CheckboxLabel text="NHC Delivering" /> },
              { value: 'collected', label: <CheckboxLabel text="Collected" /> },
              { value: 'sent', label: <CheckboxLabel text="Sent" /> },
              { value: 'ordered', label: <CheckboxLabel text="Ordered" /> },
              { value: 'picked', label: <CheckboxLabel text="Picked" /> },
            ]}
          />

        </div>

        <div className={styles.right}>

          {!legacy && (
            <React.Fragment>

              <Field
                name="order_id"
                label="Order No"
                component={SelectField}
                disabled={submitting}
                labelCol={{ span: labelColSpan }}
                wrapperCol={{ span: wrapperColSpan }}
                options={orderNumberOptions}
                onSearch={onChangeOrderSearch}
                onChange={onChangeOrder}
                onBlur={onBlurOrder}
                required
              />

              <div style={{ height: 25, marginLeft: 132, display: 'flex', alignItems: 'start' }}>

                <NavLink to={`/portal/orders/${orderId}`}>
                  &#x2192; Go to Order
                </NavLink>

              </div>

              <Field
                name="plot_number"
                label="Plot No"
                component={InputField}
                disabled
                labelCol={{ span: labelColSpan }}
                wrapperCol={{ span: wrapperColSpan }}
              />

              <Field
                name="site"
                label="Site"
                component={InputField}
                disabled
                labelCol={{ span: labelColSpan }}
                wrapperCol={{ span: wrapperColSpan }}
              />

              <Field
                name="delivery_date"
                label="Delivery"
                component={DatePickerField}
                labelCol={{ span: labelColSpan }}
                wrapperCol={{ span: wrapperColSpan }}
              />

            </React.Fragment>
          )}

          {!!legacy && (
            <React.Fragment>

              <Field
                name="legacy_plot_number"
                label="Plot No"
                component={InputField}
                disabled={submitting}
                labelCol={{ span: labelColSpan }}
                wrapperCol={{ span: wrapperColSpan }}
                required
              />

              <Field
                name="legacy_site"
                label="Site"
                component={InputField}
                disabled={submitting}
                labelCol={{ span: labelColSpan }}
                wrapperCol={{ span: wrapperColSpan }}
                required
              />

            </React.Fragment>
          )}


          <Field
            name="notes"
            label="Notes"
            component={TextAreaField}
            disabled={submitting}
            labelCol={{ span: labelColSpan }}
            wrapperCol={{ span: wrapperColSpan }}
            style={{ height: 130 }}
          />

          <br />

          <Field
            name="piggyback_order_id"
            label="Piggyback No"
            component={SelectField}
            disabled={submitting}
            labelCol={{ span: labelColSpan }}
            wrapperCol={{ span: wrapperColSpan }}
            options={piggybackOrderNumberOptions}
            onSearch={onChangePiggybackOrderSearch}
            onBlur={onBlurPiggybackOrder}
          />

        </div>

      </div>

    )
  }
}
