import React from 'react'
import PropTypes from 'prop-types'
import HeightSpacer from '../HeightSpacer'
import DimensionsModal from '../../../../Modals/Dimensions'
import Text from '../Text'
import NotesModal from '../NotesModal'
import float from '../../Utils/float'

import Controls from './Components/Controls'
import Headings from './Components/Headings'
import Item from './Components/Item'
import Static from './Components/Static'
import Placeholder from './Components/Placeholder'

const Materials = props => {

  const {
    order,
    items,
    showAncCost,
    showRebateCost,
    dimensionsModal,
    notesModal,
    onCloseDimensionsModal,
    onCloseNotesModal,
  } = props

  return (
    
    <React.Fragment>

      <Controls />

      <HeightSpacer type='controls' />

      {!!items.length && <Headings />}

      {items.map(item => (
        <React.Fragment key={item.guid}>
          <Item item={item} />
          <div style={{ all: 'unset' }}>
            {(!item.on_cost_sheet || !item.on_job_sheet || !item.on_supply_sheet) && (
              <Text 
                icon='eye-invisible' 
                value={`Hidden from ${[
                  !item.on_cost_sheet && 'Cost Sheet',
                  !item.on_job_sheet && 'Job Sheet',
                  !item.on_supply_sheet && 'Supply Sheet'
                ].filter(Boolean).join(', ')}`}
              />
            )}

            {item.supply_sheet_notes && <Text icon='info-circle' value={item.supply_sheet_notes} />}
          </div>
        </React.Fragment>
      ))}

      {!!items.length && showAncCost && <Static item={{ title: 'Ancillaries', cost: float(order.cost_sheet.anc_cost), field: 'anc_cost' }} />}

      {!!items.length && showRebateCost && <Static item={{ title: 'Rebate', cost: float(order.cost_sheet.rebate_cost), field: 'rebate_cost' }} />}

      {!items.length && <Placeholder />}

      {dimensionsModal.show && (
        <DimensionsModal
          {...dimensionsModal}
          onClose={onCloseDimensionsModal}
        />
      )}

      {notesModal.show && (
        <NotesModal
          {...notesModal}
          onClose={onCloseNotesModal}
        />
      )}

    </React.Fragment>
    
  )

}

Materials.propTypes = {
  order: PropTypes.object.isRequired,
  items: PropTypes.array.isRequired,
  showAncCost: PropTypes.bool.isRequired,
  showRebateCost: PropTypes.bool.isRequired,
  dimensionsModal: PropTypes.object.isRequired,
  notesModal: PropTypes.object.isRequired,
  onCloseDimensionsModal: PropTypes.func.isRequired,
  onCloseNotesModal: PropTypes.func.isRequired,
}

export default Materials
