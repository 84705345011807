import React from 'react'
import { Modal, message } from 'antd'
import { find, intersection } from 'lodash'
import moment from 'moment'
import { getFitterAvailabilityForDates } from '../../../HttpRequests/fitters'
import { updateJob, getJobPiggybacked } from '../../../HttpRequests/jobs'
import fetchData from './fetchData'
import handleException from '../../../Core/Helpers/handleException'

const bankHolidays = [
  '2025-04-18',
  '2025-04-21',
  '2025-05-05',
  '2025-05-26',
  '2025-08-25',
  '2025-12-25',
  '2025-12-26',

  '2026-01-01',
  '2026-04-03',
  '2026-04-06',
  '2026-05-04',
  '2026-05-25',
  '2026-08-31',
  '2026-12-25',
  '2026-12-28',
]

export default (id, newStartDate) => async (dispatch, getState) => {

  try {

    const job = find(getState().screens.Board.jobs, item => item.id === id)

    const diff = moment(job.end_date).diff(job.start_date, 'days')

    const newEndDate = moment(newStartDate).add(diff, 'days').format('YYYY-MM-DD')
    
    const { order } = job

    const moveDetails = (
      <div>
        <div>{`Fitter: ${job.fitter ? job.fitter.name : 'Unallocated'}`}</div>
        <div>{`Job: ${order ? order.plot_number : job.legacy_plot_number } ${order ? `${order.site ? order.site.name : order.site_custom} @ ${order.site ? order.site.town : order.site_custom}` : job.legacy_site}`}</div>
        <div>{`Current Dates: ${moment(job.start_date).format('DD/MM/YYYY')} ${diff ? ` - ${moment(job.end_date).format('DD/MM/YYYY')}` : ''}`}</div>
        <div>{`New Dates: ${moment(newStartDate).format('DD/MM/YYYY')} ${diff ? ` - ${moment(newEndDate).format('DD/MM/YYYY')}` : ''}`}</div>
      </div>
    )

    let proceedWithMove = await new Promise(resolve => {
      Modal.confirm({
        title: 'Move Job',
        content : moveDetails,
        okText: "Confirm",
        onOk: () => resolve(true),
        onCancel: () => resolve(false),
      })
    }) 

    if (!proceedWithMove) return

    const $diff = moment(newEndDate).diff(newStartDate, 'days')

    const dates = []

    // eslint-disable-next-line no-plusplus
    for (let i = 0; i <= $diff; i++) {
      const date = moment(newStartDate)
        .add(i, 'days')
        .format('YYYY-MM-DD')
      dates.push(date)
    }

    if (intersection(dates, bankHolidays).length > 0) {
      proceedWithMove = await new Promise(resolve => {
        Modal.confirm({
          title: 'Bank Holiday',
          content: 'The selected dates fall on a bank holiday.',
          okText: "Move Anyway",
          cancelText: "Don't Move",
          onOk: () => resolve(true),
          onCancel: () => resolve(false),
          width: 530,
        })
      })
    }

    if (job.fitter) {

      const availabilityResponse = await getFitterAvailabilityForDates({
        id: job.fitter.id,
        params: {
          start_date: newStartDate,
          end_date: newEndDate,
          exclude_job_id: job.id,
        }
      })

      const availability = availabilityResponse.data.data;

      if (availability.assigned_to_job) {

        proceedWithMove = await new Promise(resolve => {
          Modal.confirm({
            title: 'Fitter Assigned',
            content : 'This Fitter has already been assigned to another job on these dates.',
            okText: "Move Anyway",
            cancelText: "Don't Move",
            onOk: () => resolve(true),
            onCancel: () => resolve(false),
            width: 530,
          })
        })

      } else if (availability.on_holiday) {

        proceedWithMove = await new Promise(resolve => {
          Modal.confirm({
            title: 'Fitter on Holiday',
            content: 'This Fitter has a holiday booked during these dates.',
            okText: "Move Anyway",
            cancelText: "Don't Move",
            onOk: () => resolve(true),
            onCancel: () => resolve(false),
            width: 500,
          })
        })
      }

    }

    if (!proceedWithMove) return

    if (job.tag !== 'Remedial' && !!job.fitter_id) {

      const { data: piggybacked } = await getJobPiggybacked({ id: job.id })

      if (piggybacked.length) {

        const remedials = piggybacked.map(remedial => ({
          ...remedial,
          formatted: `
            ${remedial.collected ? '◆ ' : ''}
            ${remedial.sent ? '✓ ' : ''}
            ${remedial.approved === 0 ? '★ ' : ''}
            ${remedial.via_thetford ? 'T ' : ''}
            ${remedial.order ? remedial.order.plot_number : remedial.legacy_plot_number}
            ${remedial.order ? `${remedial.order.site ? remedial.order.site.name : remedial.order.site_custom} @ ${remedial.order.site ? remedial.order.site.town : remedial.order.site_custom}` : remedial.legacy_site}
            ${' | '}
            ${remedial.metres ? `${remedial.metres}${remedial.town_house ? ' T/H' : ''} | ` : ''}
            ${remedial.fitter.name}
          `,
        }))

        proceedWithMove = await new Promise(resolve => {
          Modal.confirm({
            title: 'Remedial Piggybacking',
            content: (
              <div>
                <div>{`There are remedial jobs that ${job.fitter.name} has been assigned to which may be 'piggybacking' the job that you're trying to move:`}</div>
                <br />
                <ul>
                  {remedials.map(remedial => (
                    <li key={remedial.id}>{remedial.formatted}</li>
                  ))}
                </ul>
                <div>Are you sure you want to continue? If so, please alert customer care of this move.</div>
              </div>
            ),
            okText: 'Continue',
            onOk: () => resolve(true),
            onCancel: () => resolve(false),
            width: 500,
          })
        })

        if (!proceedWithMove) return

      }

    }

    await updateJob({
      id: job.id,
      data: {
        start_date: newStartDate,
        end_date: newEndDate,
      }
    })

    message.success('Job Moved')

    dispatch(fetchData())

  } catch (error) {

    handleException(error)

  }

}